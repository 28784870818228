import React, { Component } from 'react';

import { SvgIco } from "../../SharedComponents/Icons";

import './NavigationFilter.sass';


class NavigationFilter extends Component {

  state = {
    selectedKinds: [],
  };

  componentDidMount() {
    this.props.setNavigationContainerRef(this.navigationContainerRef);
  }

  handleKindClick = (e) => {
    this.props.handleKindsSelect(e.target.value)
  };

  render() {

    const { updatedKinds, selectedKinds, topMenuHeight } = this.props;
    const scrollTargetBottom = { bottom: topMenuHeight };

    return (
      <div className="ep-navigation-filter">
        <div className="ep-navigation-filter__kinds-wrapper">
          { updatedKinds.map(kind =>
            <label
              className="ep-navigation-filter__kind-label"
              key={kind.key}
            >
              <input
                checked={selectedKinds.includes(kind.key)}
                type="checkbox"
                className="ep-navigation-filter__kind-checkbox"
                value={kind.key}
                onChange={this.handleKindClick} />
              <div className="ep-navigation-filter__checkbox-icon">
                <SvgIco name="check" size={16} />
              </div>
              { kind.label }
            </label>
          )}
        </div>
        <div ref={el => this.navigationContainerRef = el} className="ep-navigation-filter__scroll-target" style={scrollTargetBottom} />
      </div>
    )
  }
}

export default React.memo(NavigationFilter);
