export const urlKindsQueryBuilder = (url, itemSlug, selectedKinds, singleKindUrl) => {
  const kinds = selectedKinds || [];
  const slug = itemSlug || '';
  const initialUrl = url || '';
  const kindsUrl = kinds.length && kinds.length !== 1 ? '?' + selectedKinds.map(kind => 'kinds%5B%5D=' + kind).join('&') : '';
  return kinds.length !== 1 ? `${ initialUrl }${ slug }${ kindsUrl }` : `${ singleKindUrl }${ slug }`;
};

export const queryBuilder = (query) => {

  if (query.length) {
    const state = {query: query};
    window.history.pushState(state, 'title', `${window.location.pathname}?${query}`);
  } else {
    const state = {query: query};
    window.history.pushState(state, 'title', `${window.location.pathname}`);
  }
};
