import React from 'react';

import './index.sass';


const PopularAreas = ({ popularDistricts }) => {
  return (
    <div className="ep-popular-areas">
      <div className="container">
        <h2 className="ep-popular-areas__title">
          { I18n.t('apps.entry_page.entry_pages.popular_areas.headline') }
        </h2>
        <div className="ep-popular-areas__list">
          { popularDistricts.map((link, index) =>
            <a href={link.url} className="ep-popular-areas__link" key={index}>{ link.label }</a>
          )}
        </div>
      </div>
    </div>
  )
};

export default PopularAreas;
