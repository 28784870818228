import React, { Component } from "react";
import Yup from 'yup';
import { withFormik } from 'formik';
import { validationPatterns } from '../../../utils/validationPatterns';
import { SvgIco } from '../../SharedComponents/Icons';
import DataProtectionModal from '../../SharedComponents/DataProtectionModal';

import './NewsletterWidget.sass';


class NewsletterWidgetWrapper extends Component {

  state = {
    isShowingModal: false,
  };

  openInfoModal = () =>
    this.setState({ isShowingModal: true });


  closeInfoModal = () =>
    this.setState({ isShowingModal: false });


  render() {
    const {
      errors,
      handleBlur,
      handleChange,
      handleSubmit,
      touched,
      values,
      resubscribeLink,
      submitError,
      submitSuccess,
      hideSubmitError,
    } = this.props;


    return (
      <div className={`ep-newsletter-widget${submitSuccess ? ' success' : ''}`}>
        { !submitSuccess
          ?
            <div className='ep-newsletter-widget__wrapper'>
              <div className="ep-newsletter-widget__headline-wrapper">
                <div className="ep-newsletter-widget__headline">
                  <SvgIco name="email-newsletter" width={64} height={40} />
                  <p className="ep-newsletter-widget__title">
                    { I18n.t('apps.entry_page.widgets.news_widget.main_title') }
                  </p>
                </div>
                <div className="ep-newsletter-widget__description">
                  { I18n.t('apps.entry_page.widgets.news_widget.description') }
                </div>
              </div>

              <div className="ep-newsletter-widget__form-wrapper">
                <form name="widget-form" className="ep-newsletter-widget__form" onSubmit={ handleSubmit } noValidate>
                  <div className='form-group'>
                    <label className="ep-newsletter-widget__form-label" htmlFor="name">
                      <input
                        name="name"
                        type="text"
                        value={values.name}
                        id="name"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className={`ep-newsletter-widget__form-input ${values.name && 'active'}`}
                        autoComplete="off"
                      />
                      <span className={`ep-newsletter-widget__form-floating-label ${values.name && 'active'}`}>
                        { nameI18n  + '*' }
                      </span>

                    </label>
                    <div className="ep-newsletter-widget__form-input-tooltip-error">
                      { touched.name ? errors.name : null }
                    </div>
                  </div>

                  <div className='form-group'>
                    <label className="ep-newsletter-widget__form-label" htmlFor="email">
                      <input
                        name="email"
                        type="email"
                        value={values.email}
                        id="email"
                        onChange={(e) => {
                          handleChange(e);
                          submitError && hideSubmitError();
                        }}
                        onBlur={handleBlur}
                        className={`ep-newsletter-widget__form-input ${values.email  && 'active'}`}
                      />
                      <span className={`ep-newsletter-widget__form-floating-label ${values.email && 'active'}`}>
                        { emailI18n + '*' }
                      </span>
                    </label>
                    <div className="ep-newsletter-widget__form-input-tooltip-error">
                      { touched.email ? errors.email : null }
                    </div>

                    { submitError &&  (
                      <div className="ep-newsletter-widget__servers-error">
                        { submitError }
                      </div>
                    )}
                  </div>

                  { resubscribeLink &&
                    <a className="ep-newsletter-widget__form-resubscribe-link" href={resubscribeLink}>
                      { I18n.t('apps.entry_page.widgets.news_widget.resubscribe_link')}
                    </a>
                  }

                  <div className="form-group form-group--not-required ep-newsletter-widget__form-btn-submit">
                    <button type="submit" className="b-button">
                      { I18n.t('apps.entry_page.widgets.news_widget.submit-button') }
                    </button>
                  </div>
                </form>
                <DataProtectionModal
                  isShowingModal={ this.state.isShowingModal }
                  handleClose={this.closeInfoModal}
                  title={I18n.t('apps.lb_showroom.detailed.order_form.data_protection')}
                  content={I18n.t('apps.lb_showroom.detailed.order_form.modal.data_protection_body')}
                />

                <div onClick={this.openInfoModal} className="policy-info-description">
                  <SvgIco name="lock" size={22} />
                  <span>{I18n.t('apps.lb_showroom.detailed.order_form.data_protection')}</span>
                </div>
              </div>

            </div>
          :
            <div className="ep-newsletter-widget__success-content">
              <div className="ep-newsletter-widget__headline">
                <img data-src={require(`../../../src/images/entry_pages/email.svg`)}
                     alt="pin"
                     className="ep-newsletter-widget__pin lazyload"
                     data-sizes="auto"
                     height={40}
                />
                <p className="ep-newsletter-widget__title">
                  { I18n.t('apps.entry_page.widgets.news_widget.success_signup') }
                </p>
              </div>
              <div className="ep-newsletter-widget__description">
                { I18n.t('apps.entry_page.widgets.news_widget.success_description') }
              </div>
            </div>
        }
      </div>
    )
  }
}

const nameI18n = I18n.t('generic.name');
const emailI18n = I18n.t('signup.new_form.register.email.placeholder');
const i18nRequiredKey = 'apps.lb_showroom.detailed.order_form.validation.field_required';
const i18nInvalidNameInput = 'apps.lb_showroom.misc.scoped_form.name.invalid';
const i18nInvalidEmailInput = 'apps.lb_showroom.detailed.order_form.validation.wrong_email';
const i18nTooManySimilarSymbols = 'apps.lb_showroom.detailed.order_form.validation.too_many_similar_symbols';
const i18nAtLeastOneLetterIsRequired = 'apps.lb_showroom.detailed.order_form.validation.at_least_one_letter';
const i18nSymbolsLimitErrorMessage = I18n.t('apps.lb_showroom.misc.scoped_form.max_symbols_limit', { symbols_limit: 254 });
const i18nSymbolsEmailLimitErrorMessage = I18n.t('apps.lb_showroom.misc.scoped_form.max_symbols_limit', { symbols_limit: 100 });

export const NewsletterWidget = withFormik({
  mapPropsToValues: () => ({ name: '', email: '' }),

  validationSchema: Yup.object().shape({
    name: Yup.string()
      .matches(validationPatterns.symbolsLimit, i18nSymbolsLimitErrorMessage)
      .matches(validationPatterns.name, I18n.t(i18nInvalidNameInput))
      .matches(validationPatterns.specialCharacktersNotMoreThan, I18n.t(i18nTooManySimilarSymbols))
      .matches(validationPatterns.atLeastOneLetter, I18n.t(i18nAtLeastOneLetterIsRequired))
      .required(I18n.t(i18nRequiredKey, {field: nameI18n})),

    email: Yup.string()
      .matches(validationPatterns.symbolsEmailLimit, i18nSymbolsEmailLimitErrorMessage)
      .email(I18n.t(i18nInvalidEmailInput))
      .required(I18n.t(i18nRequiredKey, {field: emailI18n})),
  }),

  handleSubmit: (values, bag) => {
    bag.props.newsletterSubmitHandler(values);
  },

  displayName: 'NewsletterWidget',
})(NewsletterWidgetWrapper);


export default NewsletterWidget;
