import "scroll-behavior-polyfill";

import React, { Component, Fragment } from 'react';
import classNames from 'classnames';
import { isMobile, isTablet } from 'react-device-detect';

import './index.sass';


class BaseSlider extends Component {

  state = {
    slidesToScroll: 0,
    indicatorsCount: 0,
    sliderLeftBoundary: 0,
    sliderRightBoundary: 0,
    activeIndicator: '',
    showIndicators: false,
    wrapperHeight: 0,
    showArrows: false,
    navigationDisabled: false,
    slideWidth: 0,
    sliderHeight: 0,
    disableLeftArrow: true,
    disableRightArrow: false,
  };

  componentDidMount() {
    this.setSliderState();
    window.addEventListener('resize', this.handleResize);
    window.addEventListener('orientationchange', this.handleResize);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.children.length !== prevProps.children.length) {
      this.setSliderState();
    }

    if (this.state.navigationDisabled !== prevState.navigationDisabled || this.state.showIndicators !== prevState.showIndicators) {
      if (!this.state.navigationDisabled) {
        this.setState({ activeIndicator: 'indicator0' }, () => this.sliderWrapper.addEventListener('scroll', this.handleSliderScroll))
      } else {
        this.sliderWrapper.removeEventListener('scroll', this.handleSliderScroll);
        this.setState({ disableLeftArrow: true, disableRightArrow: false });
        this.setSliderState();
      }
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
    window.removeEventListener('orientationchange', this.handleResize);
    this.sliderWrapper.removeEventListener('scroll', this.handleSliderScroll);
  }

  handleResize = () => {
    this.sliderWrapper.scrollLeft = 0;
    this.setSliderState();
  };

  setSliderState = () => {
    const { showSlidesIndicators, children } = this.props;
    const slidesToScroll =  Math.round(this.sliderWrapper.offsetWidth / this.sliderWrapper.children[0].offsetWidth);
    const navigationDisabled = this.sliderWrapper.children.length <= slidesToScroll;

    let updatedState = {
      sliderLeftBoundary: this.sliderWrapper.getBoundingClientRect().left,
      sliderRightBoundary: Math.floor(this.sliderWrapper.getBoundingClientRect().right),
      slideWidth: this.slide0.offsetWidth,
      slidesToScroll,
      navigationDisabled,
    };

    if (showSlidesIndicators && !navigationDisabled) {
      const indicatorsCount = Math.ceil(children.length / slidesToScroll);

      updatedState = { ...updatedState, indicatorsCount, showIndicators: true };
    }

    this.setState({ ...updatedState });
    setTimeout(() => this.setState({ sliderHeight: this.sliderWrapper.offsetHeight - 30 }), 40);
  };

  handleSliderScroll = () => {
    // The viewPort is the left part of slider with Slide width
    const { sliderLeftBoundary, slideWidth, sliderRightBoundary, navigationDisabled } = this.state;
    const viewPortRightBorder = sliderLeftBoundary + slideWidth;
    const isSliderEnd = Math.floor(this.sliderWrapper.lastChild.getBoundingClientRect().right) <= sliderRightBoundary;
    const isSliderStart = this.sliderWrapper.firstChild.getBoundingClientRect().left === sliderLeftBoundary;

    !navigationDisabled &&
    this.setState({
      disableLeftArrow: isSliderStart,
      disableRightArrow: isSliderEnd,
    });

    // In case this is the end of slider give active class to the last indicator
    if (isSliderEnd) {
      this.handleActiveIndicatorChange(this.indicatorsWrapper.lastChild, this.indicatorsWrapper.lastChild.dataset.name);
    } else {
      Array.from(this.slider.querySelectorAll('.base-slider__indicator')).forEach(indicator => {
        const indicatorTargetSlideLeftBoundary = Math.ceil(this[indicator.dataset.target].getBoundingClientRect().left);

        if (indicatorTargetSlideLeftBoundary >= sliderLeftBoundary && indicatorTargetSlideLeftBoundary < viewPortRightBorder) {
          this.handleActiveIndicatorChange(indicator, indicator.dataset.name);
        }
      });
    }
  };

  handleActiveIndicatorChange = (targetIndicator, targetIndicatorName) => {
    const { activeIndicator } = this.state;

    this[activeIndicator] && this[activeIndicator].classList.remove('active');
    targetIndicator.classList.add('active');
    this.setState({ activeIndicator: targetIndicatorName })
  };

  renderSlidesIndicators = () => {
    const { slidesToScroll, indicatorsCount, activeIndicator } = this.state;
    const indicators = [];
    const slidesCount = this.sliderWrapper.children.length - 1;
    let count = indicatorsCount;
    let slideIndexIterator = 0;

    while (count) {
      const targetSlide = `slide${slideIndexIterator}`;
      const indicatorRef = `indicator${slideIndexIterator}`;

      indicators.push(
        <div className={`base-slider__indicator${ activeIndicator === indicatorRef ? ' active' : '' }`}
             data-name={indicatorRef}
             data-target={targetSlide}
             onClick={() => this.handleIndicatorClick(targetSlide)}
             key={count}
             ref={el => this[indicatorRef] = el }/>
      );

      // In case the quantity of slides on the last page is less than slidesToScroll the last indicator will receive the last slide index
      slideIndexIterator = (slideIndexIterator + slidesToScroll) > slidesCount ? slidesCount : slideIndexIterator + slidesToScroll;
      count--;
    }

    return (
      <Fragment>
        { indicators.map(indicator => indicator) }
      </Fragment>
    )
  };

  handleIndicatorClick = (targetSlide) => {
    const targetLeftBoundary = this[targetSlide].getBoundingClientRect().left;

    if(targetLeftBoundary !== 0) {
      this.sliderWrapper.scrollLeft = (targetLeftBoundary - this.state.sliderLeftBoundary) + this.sliderWrapper.scrollLeft;
    }
  };

  handleLeftButtonClick = () =>
    this.sliderWrapper.scrollLeft = this.sliderWrapper.scrollLeft - this.sliderWrapper.offsetWidth;

  handleRightButtonClick = () =>
    this.sliderWrapper.scrollLeft = this.sliderWrapper.offsetWidth + this.sliderWrapper.scrollLeft;


  render() {
    const { children, slideClass, wrapperClass, customArrowLeft, customArrowRight, disableAroows } = this.props;
    const { navigationDisabled, sliderHeight, showIndicators, disableLeftArrow, disableRightArrow } = this.state;
    const sliderHeightStyle = sliderHeight ? { height: sliderHeight + 'px' } : null;
    const slideClasses = classNames('base-slider__slide', { [slideClass]: slideClass });
    const leftArrowClasses = classNames('base-slider__arrow base-slider__arrow--left', { 'disabled': disableLeftArrow });
    const rightArrowClasses = classNames('base-slider__arrow base-slider__arrow--right', { 'disabled': disableRightArrow });
    const wrapperClasses = classNames('base-slider__wrapper row', {
      'scrollable': isMobile || isTablet,
      [wrapperClass]: wrapperClass,
    });


    return (
      <div className="base-slider" ref={ el => this.slider = el }>

        { !navigationDisabled && !isMobile && !disableAroows && (
          <div className={leftArrowClasses} onClick={this.handleLeftButtonClick}>
            { customArrowLeft || null}
          </div>
        )}

        <div className="base-slider__viewport" style={sliderHeightStyle}>
          <div className={wrapperClasses} ref={ el => this.sliderWrapper = el } style={{ scrollBehavior: 'smooth' }}>
            { children.map((component, index) =>
              <div id={`slide-item-${index}`} key={index} className={slideClasses} ref={ el => this[`slide${index}`] = el }>
                { component }
              </div>
            )}
          </div>
        </div>

        { !navigationDisabled && !isMobile && !disableAroows && (
          <div className={rightArrowClasses} onClick={this.handleRightButtonClick}>
            { customArrowRight || null }
          </div>
        )}

        { showIndicators && !navigationDisabled && (
          <div className="base-slider__indicators" ref={el => this.indicatorsWrapper = el}>
            { this.renderSlidesIndicators() }
          </div>
        )}

      </div>
    )
  }
}

export default BaseSlider;
