import React, { Component } from 'react';

import * as helpers from "../../../utils/helpers";
import NavigationFilter from "./NavigationFilter";
import LocationNavigator from "./LocationNavigator";

import './index.sass';


class LocationNavigationSection extends Component {

  state = {
    selectedKinds: [],
    navigationFilterRef: '',
    navigationContainerRef: '',
  };

  componentDidMount() {
    const preselectedKinds = this.props.searchAreaConfiguration.searchOptions.preselectedKinds;

    preselectedKinds.length > 0 && preselectedKinds.map(elem => this.handleKindsSelect(elem));
    this.setState({ navigationContainerRef: this.navigationContainerRef });
  }

  handleKindsSelect = (kind) => {
    const { selectedKinds } = this.state;
    const updatedKinds = selectedKinds.indexOf(kind) >= 0 ? selectedKinds.filter(selectedKind => selectedKind !== kind) : [...selectedKinds, kind];

    this.setState({ selectedKinds: updatedKinds });
    kind === 'parking' && this.props.handleParkingLogos();
  };

  setNavigationContainerRef = (ref) => this.setState({ navigationFilterRef: ref });

  render() {

    const { selectedKinds, navigationContainerRef, navigationFilterRef } = this.state;
    const topMenuHeight = document.getElementById('top-menu').offsetHeight;
    const scrollTargetTop = { top: -topMenuHeight };

    const {
      navigationSeoContent,
      mapImg,
      siteKey,
      subSite,
      chooseProvinceJsArgs,
      allCountries,
      updatedKinds,
      searchAreaConfiguration,
      currentLocalePathSegment,
    } = this.props;


    return (
      <div className="ep-geo-navigation container">
        <h2 className="ep-geo-navigation__title">
          { siteKey === 'global'
            ? I18n.t('entry_pages.choose_district.title')
            : I18n.t('entry_pages.choose_province.title')
          }
        </h2>
        <div className="ep-geo-navigation__scroll-target" ref={el => this.navigationContainerRef = el} style={scrollTargetTop}/>
        <div className="ep-geo-navigation__welcome-block row">
          <div className="ep-geo-navigation__welcome-content col-xl-9 col-lg-11 col-md-12" dangerouslySetInnerHTML={{ __html: helpers.parseMd(navigationSeoContent) }} />
        </div>
        <div className="ep-geo-navigation__navigation-wrapper">
          <NavigationFilter
            siteKey={siteKey}
            handleKindsSelect={this.handleKindsSelect}
            updatedKinds={updatedKinds}
            searchAreaConfiguration={searchAreaConfiguration}
            selectedKinds={selectedKinds}
            topMenuHeight={topMenuHeight}
            setNavigationContainerRef={this.setNavigationContainerRef}
          />
          <LocationNavigator
            mapImg={mapImg}
            siteKey={siteKey}
            subSite={subSite}
            chooseProvinceJsArgs={chooseProvinceJsArgs}
            selectedKinds={selectedKinds}
            allCountries={allCountries}
            navigationFilterRef={navigationFilterRef}
            navigationContainerRef={navigationContainerRef}
            currentLocalePathSegment={currentLocalePathSegment}
          />
        </div>
      </div>
    )
  }
}

export default LocationNavigationSection;
