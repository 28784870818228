export const findObjectById = (array, searchFieldName, searchFieldValue) => {
  let foundedObject = null;

  array.forEach(object => {
    if(object[searchFieldName] == searchFieldValue) foundedObject = object;
  })

  return object;
}

export const arrayIncludesObject = (array, searchFieldName, searchFieldValue) => {
  let arrayIncludeObject = false;

  array.forEach(object => {
    if(object[searchFieldName] === searchFieldValue) arrayIncludeObject = true;
  })

  return arrayIncludeObject;
}

export const getObjectIndexInArray = (array, searchFieldName, searchFieldValue) => {
  let objectIndex = -1;

  array.forEach((object, index) => {
    if(object[searchFieldName] === searchFieldValue) objectIndex = index;
  })

  return objectIndex;
}
