import React from 'react';

import { SvgIco } from '../../SharedComponents/Icons';

import './index.sass';


const Benefits = ({ advantagesTranslations, country, preselectedKind }) => {

  let kindForTranslation = preselectedKind[0] || 'second';


  return (
    <div className="benefits container">
      <h2 className="benefits__title">{ I18n.t('apps.entry_page.benefits_headline') }</h2>
      <div className="row">
        <div className="col-xl-4 col-md-4 benefits__item">
          <div className="benefits__icon-wrapper">
            <SvgIco name="free" size={48} />
          </div>
          <span className="benefits__description">
            { I18n.t(`apps.entry_page.search_area.value_points.${advantagesTranslations}.one_full`) }
          </span>
        </div>
        <div className="col-xl-4 col-md-4  benefits__item">
          <div className="benefits__icon-wrapper">
            <SvgIco name="location" size={48} />
          </div>
          <span className="benefits__description">
            { I18n.t(`apps.entry_page.search_area.value_points.${advantagesTranslations}.${kindForTranslation}`, {'country_name': country}) }
          </span>
        </div>
        <div className="col-xl-4 col-md-4  benefits__item">
          <div className="benefits__icon-wrapper">
            <SvgIco name="save" size={48} />
          </div>
          <span className="benefits__description">
            { I18n.t(`apps.entry_page.search_area.value_points.${advantagesTranslations}.third`) }
          </span>
        </div>
      </div>
    </div>
  )
};

export default Benefits;
