import React, { Component, Fragment } from 'react';

import * as helpers from '../../../utils/helpers';
import { SvgIco } from '../../SharedComponents/Icons';

import './index.sass';


class BottomSeo extends Component {

  state = {
    readMore: false,
  };

  handleReadMore = () => {
    this.setState({ readMore: !this.state.readMore });
  };

  render() {
    const { bottomSeoBody, bottomseoCompleteBody, bottomseoHeadline, } = this.props;
    const { readMore } = this.state;

    return (
      <div className="ep-bottom-seo bg-stage lazyload">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="ep-bottom-seo__teaser-area">
                <div dangerouslySetInnerHTML={{ __html: helpers.parseMd(bottomseoHeadline) }} />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="ep-bottom-seo__body">
                <div className="ep-bottom-seo__columns">
                <span className="ep-bottom-seo__teaser" ref={ el => this.contentWrapper = el }>
                  <div dangerouslySetInnerHTML={{ __html: helpers.parseMd(bottomSeoBody) }} />
                </span>
                <span className={`ep-bottom-seo__complete ${ readMore && 'inline' }`}>
                  <div dangerouslySetInnerHTML={{ __html: helpers.parseMd(bottomseoCompleteBody) }}/>
                </span>

                  <span className="ep-bottom-seo__read-more" onClick={ this.handleReadMore }>
                    { readMore
                      ? (
                        <Fragment>
                          { I18n.t('generic.close') }
                          <SvgIco name="expand-less" size={19} />
                        </Fragment>
                      )
                      : (
                        <Fragment>
                          { I18n.t('locations.seo_friendly_summary.read_more') }
                          <SvgIco name="expand-more" size={19} />
                        </Fragment>
                      )
                    }
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default BottomSeo;
