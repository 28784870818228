import React from "react";

import BaseSlider from '../../SharedComponents/BaseSlider';
import { KeyboardArrowLeftRoundedIos, KeyboardArrowRightRoundedIos } from '../../SharedComponents/Icons';
import LocationTemplate from '../../SharedComponents/LocationTemplate';

import './index.sass';


const LatestLocations = ({ latestLocations, headline, siteKey, section }) =>  {

  return (
    <div className="ep-latest-locations">
      <div className="container">
        <div className="ep-latest-locations__title">
          { headline }
        </div>
      </div>

      <div className="ep-latest-locations__slider-wrapper container">
        <BaseSlider slideClass="col-xl-3 col-lg-4 col-md-6 col-sm-5"
                    showSlidesIndicators={ true }
                    customArrowLeft={ <KeyboardArrowLeftRoundedIos size={ 32 }/> }
                    customArrowRight={ <KeyboardArrowRightRoundedIos size={ 32 }/> }
                    disableAroows={true}
        >
          { latestLocations.map(item => (
            <LocationTemplate
              data={item}
              hidePrice={true}
              key={item.uuid}
              section={section}
              siteKey={siteKey}
              showDate={true}
            />
          )) }
        </BaseSlider>
      </div>
    </div>
  )
};

export default LatestLocations;
