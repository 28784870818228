import React from 'react';
import { emitter, Experiment, Variant } from "@marvelapp/react-ab-test";
import { isMobileOnly } from 'react-device-detect';

import bannerIllustration from '../../../src/images/entry_pages/banners-illustration.svg';
import { apiProvider } from "../../../api/core";
import { SvgIco } from "../../SharedComponents/Icons";

import './ListViewBanner.sass';

emitter.defineVariants(
  'list-office-link',
  ['ep_lo_new', 'ep_lo_old'],
  [50, 50]
);


const ListViewBanner = ({ signUpTeaserPass, siteKey, visitorToken }) => {

  const handleClick = (e) => {
    e.preventDefault();

    const url = 'api/frontend/ab_test_cases';
    const variant = emitter.getActiveVariant('list-office-link');
    const params = { tag: 'list-office-link', ab_case: { code: variant, user_token: visitorToken } }

    apiProvider.post(url, params, true)
      .then(response => {
        window.open(signUpTeaserPass);
      }).catch(error => {
      console.log(error);
      window.open(signUpTeaserPass);
    });
  };


  return (
    <div className="ep-bottom-banner">
      <div className="container-fluid container-sm">
        <div className="bg-banner lazyload">
          <div className="info-block">
            <div className="main-info">
              <p className="main-info__title">{ I18n.t('entry_pages.list_view_banner.main_title') }</p>
              <p className="main-info__description">{I18n.t('entry_pages.list_view_banner.description')}</p>
              <a className="b-button btn-orange" href={signUpTeaserPass} onClick={siteKey === 'de' ? handleClick : null}>
                { siteKey === 'de'
                  ?
                    <Experiment name="list-office-link">
                      <Variant name="ep_lo_new">
                        Jetzt registrieren
                      </Variant>
                      <Variant name="ep_lo_old">
                        {I18n.t("entry_pages.list_view_banner.button")}
                      </Variant>
                    </Experiment>
                  : I18n.t("entry_pages.list_view_banner.button")
                }

                {/*{ I18n.t("entry_pages.list_view_banner.button") }*/}
                <SvgIco name="keyboard-arrow-right-rounded" size={28} />
              </a>
            </div>
            { !isMobileOnly &&
              <div className="illustration-wrapper">
                <img data-src={bannerIllustration}
                     alt="banner illustration"
                     className="lazyload"
                     data-expand="100"
                     data-sizes="auto"
                     width={215}
                     height={160}
                />
              </div>
            }
          </div>
        </div>
      </div>
    </div>

  );
};

export default ListViewBanner;
