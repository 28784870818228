import React from 'react';

import './index.sass';


const ProviderLogos = ({ providerLogos, providerParkingLogos, currentLocation, showParkingLogos }) => {

  const providerCurrentLogos = providerLogos.filter(logos => logos.indexOf(currentLocation) >= 0)[0][1];
  const logos = showParkingLogos ? providerParkingLogos : providerCurrentLogos;

  const renderLogoImage = () =>
    logos.map(logo =>
      <li className="provider-logos__image" key={logo}>
        <img alt={logo}
             className="lazyload"
             data-expand="100"
             data-src={require(`../../../src/images/entry_pages/provider_logos/${logo}`)}
             data-sizes="auto"/>
      </li>
    );


  return (
    <div className="provider-logos container">
      <div className="row">
        <div className="col-md-12">
          <div className="main-wrapper-border">
            <div className="row">
              <div className="provider-logos__text col-xs-12 col-lg-2 offset-md-1">
                <p>{I18n.t('entry_pages.partners_banner.text')}</p>
              </div>
              <div className="provider-logos__images-col col-xs-12 col-lg-8">
                <ul className="provider-logos__images-list">
                  { renderLogoImage() }
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
};

export default ProviderLogos;
