import React, { Component } from 'react';
import { isMobileOnly } from 'react-device-detect';

import { apiProvider } from '../../../api/core';
import NewsletterWidget from "./NewsletterWidget";

import './News.sass';


class News extends Component {

  state = {
    submitError: false,
    submitSuccess: false,
    resubscribeLink: '',
    articles: [],
  };

  componentDidMount() {
    apiProvider.getAll(this.props.newsArticlesPreviewPath, true)
      .then(response => {
        response && this.setState({ articles: response })
      });
  };

  hideSubmitError = () => this.setState({ submitError: false });

  newsletterSubmitHandler = (data) => {
    const { newsletterPath } = this.props;
    const bodyFormData = new FormData();

    bodyFormData.append(`email[]`, data.email);
    bodyFormData.append('name', data.name);

    apiProvider.post(newsletterPath, bodyFormData, true)
               .then(response => {
                 response.status === 200
                   ? this.setState({ submitSuccess: true })
                   : this.setState({
                       submitError: response.data.message || I18n.t('apps.entry_page.widgets.news_widget.backend-error'),
                       submitSuccess: false,
                       resubscribeLink: response.data.resubscribe_link || '',
                     });
               });
  };

  render() {
    const { submitError, submitSuccess, resubscribeLink, articles } = this.state;
    const { siteKey, newsAllLink } = this.props;
    const renderNewsletterWidget = siteKey === 'global' || siteKey === 'dk';
    const articlesData = articles && articles.length && (isMobileOnly && articles.length > 4 ? articles.slice(0, 4) : articles);


    return (
      <div className="ep-news row">
        <div className="col-lg-8 col-xl-8">
          <div className="row ep-news__articles-wrapper">
            <div className="col-xl-12">
              <p className="ep-news__headline">
                { I18n.t('apps.entry_page.widgets.news_teasers.news_teasers_headline') }
              </p>
            </div>
            { articlesData && articlesData.length && articlesData.map(article => {
              return (
                <div className="col-xl-6 col-md-6 ep-news__article-teaser" key={article.slug}>
                  <a href={article.path} target="_blank">
                    <div className="ep-news__article-img lazyload"
                         data-bg={article.image}
                         data-sizes="auto"
                         aria-labelledby="news-image"
                    />
                  </a>
                  <div className="ep-news__article-info">
                    <div className="ep-news__publish-date">
                      { article.publish_date}
                    </div>
                    <a className="ep-news__article-link truncate-overflow"
                       href={`${article.path}`}
                       target="_blank">
                      { article.title }
                    </a>
                  </div>
                </div>
              )
            })}
          </div>

          <div className="row">
            <div className="col-xl-12 ep-news__see-all-link-wrapper">
              <a href={newsAllLink} className="ep-news__see-all-link" target="_blank">
                { I18n.t('apps.entry_page.widgets.news_teasers.see_all_news_link') }
              </a>
            </div>
          </div>
        </div>

        { renderNewsletterWidget &&
          <div className={`ep-news__widget-wrapper col-lg-4 col-xl-4${submitSuccess ? ' success' : ''}`}>
            <NewsletterWidget
              submitError={submitError}
              submitSuccess={submitSuccess}
              resubscribeLink={resubscribeLink}
              newsletterSubmitHandler={this.newsletterSubmitHandler}
              hideSubmitError={this.hideSubmitError}
            />
          </div>
        }
      </div>
    )
  }
}

export default News;
