import React, { Component, Fragment } from 'react';
import { isMobile } from 'react-device-detect';
import debounce from 'lodash.debounce';

import { SvgIco } from '../../SharedComponents/Icons';
import ResultsContainerEntryPage from '../ResultContainerEntryPage/ResultContainerEntryPage';

import './LocationSearchEntryPage.sass';


class LocationSearchEntryPage extends Component {
  handleInputFocus = () => {
    if (!this.state.focusInputField) {
      this.setState({focusInputField: true})
    }
  };

  handleEnter = () => {
    if (this.linkElement && this.props.results.length !== 0) {
      this.clearInput();
      window.open(this.linkElement, "_self")
    }
  };

  handleInput = event => {
    const query = event ? event.target.value.substring(0, 256) : this.state.query;
    this.setState({ query, searchError: false });
    this.props.controlSearchQuery(query);
    this.onChangeDebounced(query);

  };

  setWrapperRef = (node) => {
    this.wrapperRef = node;
  };
  setRef = ref => {
    this.linkElement = ref;
    this.selectedLink(this.linkElement);
  };
  handleKeyDown = e => {
    switch (e.which) {
      case 38:
        this.setState({ direction: 'up' });
        break;
      case 40:
        this.setState({ direction: 'down' });
        break;
      case 27:
        this.setState({ direction: 0 });
        console.log('case 27')
        this.clearInput();
        break;
      case 13:
        this.handleEnter();
        break;
      default:
        this.setState({ direction: null });
        break;
    }
  };

  handleClickOutside = (event) => {

    const isCheckbox = event.target.closest('.select-type__checkbox-wrapper') || event.target.matches('.select-type__checkbox-wrapper');

    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      this.setState({
        focusInputField: false,
        resultContainerIsOpen: isCheckbox,
      })
    } else {
      this.setState({ resultContainerIsOpen: true, });
    }
  };

  toggleListen = () => {
    this.setState({
      listening: !this.state.listening
    }, this.handleListening)
  };
  handleListening = () => {
    if (window.hasOwnProperty('webkitSpeechRecognition')) {
      const SpeechRecognition = webkitSpeechRecognition;
      const recognition = new SpeechRecognition();

      recognition.continous = true;
      recognition.interimResults = true;
      this.props.siteKey === 'dk' ? recognition.lang = 'da-DK' : recognition.lang = 'en-US';
      let finalTranscript = '';
      if (this.state.listening) {
        recognition.start();

        recognition.onaudioend = () => {
          this.setState({
            listening: false
          })
        };

        recognition.onresult = (event) => {
          let interimTranscript = '';

          for (let i = event.resultIndex; i < event.results.length; i++) {
            const transcript = event.results[i][0].transcript;

            if (event.results[i].isFinal) {
              finalTranscript += transcript + ' ';

              let input = document.getElementById('transcript');
              let nativeInputValueSetter = Object.getOwnPropertyDescriptor(
                window.HTMLInputElement.prototype,
                "value"
              ).set;

              nativeInputValueSetter.call(input, finalTranscript.substring(0, finalTranscript.length - 1));
              let inputEvent = new Event("input", { bubbles: true });
              input.dispatchEvent(inputEvent);
            }

            else interimTranscript += transcript;
            this.setState({
              focusInputField: true,
              resultContainerIsOpen: true
            })
          }
        }
      } else {
        recognition.stop()
      }
    }
  };


  componentDidMount() {
    if (this.props.searchQueryStringFromGoogle)
      this.setState({query: this.props.searchQueryStringFromGoogle}, () => this.handleInput());
    document.addEventListener('click', this.handleClickOutside)

  };

  constructor(props) {
    super(props);
    this.state = {
      direction: null,
      listening: false,
      focusInputField: false,
      resultContainerIsOpen: true,
      query: '',
      searchError: false,
    };
    this.linkElement = null;

    this.onChangeDebounced = debounce(this.onChangeDebounced, 300)
  }
  onChangeDebounced = () => {
    if (/\S/.test(this.state.query)) {
      this.props.fetchItems(this.state.query)
    } else {
      this.props.clearResults([]);
    }
  };
  selectedLink = el => this.selectedElem = el;
  searchByLink = el => {

    if (this.state.query.length === 0) {
      this.setState({
        searchError: true
      })
    } else if (this.props.results.length === 0){
      this.selectedElem = null;
    } else {
      window.open(el, "_self")
    }
  };
  clearInput = () => {
    this.props.clearResults([]);
    this.setState({ query: '', })
  };

  render() {
    const { results, showNoResultsError, siteKey } = this.props;
    const { query, searchError } = this.state;
    const isInputFilled = !!query.length || this.state.translations;

    return (
      <Fragment>
        <div className="voice-wrapper">
          <div className={`search-input-wrapper ${this.state.focusInputField ? 'focus-input' : ''} ${isInputFilled && showNoResultsError ? 'error-input' : '' }`}>
            <SvgIco name="search-entry-page" size={isMobile ? 18 : 25} />
            <input
              className='location-search-input-entry-page'
              ref={ this.setWrapperRef }
              id="transcript"
              type="text"
              value={query}
              onChange={this.handleInput}
              placeholder={I18n.t('entry_pages.main_search.input_placeholder')}
              onKeyDown={this.handleKeyDown}
              onFocus={this.handleInputFocus}
              maxLength="256"
            />

            { isInputFilled && (
              <div className="location-search-entrypage-clear-btn" onClick={this.clearInput}>
                <SvgIco name="close" size={24} />
              </div>
            )}

            {window.hasOwnProperty('webkitSpeechRecognition') &&
              <div>
                <span onClick={this.toggleListen}
                      className={`input-group-addon voice-record-entry-page ${this.state.listening ? 'voice-record-active' : ''}`}>
                <SvgIco name="mic" size={32} />
              </span>
              </div>
            }

            <div className='main-search-enter-button enter-button-desktop' onClick={() => this.searchByLink(this.linkElement)}>
              { I18n.t('generic.search') }
            </div>

            { isInputFilled && this.state.resultContainerIsOpen && results.length ? (
              <ResultsContainerEntryPage
                results={results}
                moveDirection={this.state.direction}
                refElem={this.setRef}
              />
            ) : null}
          </div>
        </div>

        { searchError && (
          <div className="error-wrapper-entry-page">
            <div className="error-text">{ I18n.t('apps.lb_showroom.goto.no_query') }</div>
          </div>
        )}


        { isInputFilled && showNoResultsError ? (
          <div className="error-wrapper-entry-page">
            <div className="error-text">
              { I18n.t('apps.lb_showroom.goto.no_results') }&nbsp;
              { siteKey !== 'global' && (
                <a href="https://www.matchoffice.com">
                  { I18n.t('apps.lb_showroom.goto.link_to_global_site') }
                </a>
              )}
            </div>
          </div>
        ) : null }

        <div className="row enter-button-mobile">
          <div className="col-md-12">
            <div className='main-search-enter-button' onClick={() => this.searchByLink(this.selectedElem) } >
              { I18n.t('generic.search') }
            </div>
          </div>
        </div>

      </Fragment>
    );
  }
}

export default React.memo(LocationSearchEntryPage);
