import React from "react";
import News from "./News";

import './index.sass';

const AboutUs = ({ aboutUsHeadline, aboutUsContent, currentLocation, newsAvailable, siteKey, newsletterPath, newsAllLink, newsArticlesPreviewPath }) => {

  const showNews = newsAvailable && (siteKey === 'global' ? (currentLocation !== 'tr') : (siteKey !== 'no'));

  return (
    <div className="ep-about-us container">
      { showNews &&
        <News
          siteKey={siteKey}
          newsletterPath={newsletterPath}
          newsAllLink={newsAllLink}
          newsArticlesPreviewPath={newsArticlesPreviewPath}
        />
      }
      <h2 className="ep-about-us__headline">
        { aboutUsHeadline }
      </h2>
      <div className="ep-about-us__content" dangerouslySetInnerHTML={{ __html: aboutUsContent }}/>
    </div>
  )
};

export default AboutUs;
