import React, { Component } from 'react';
import './SelectLocationTypeEntryPage.sass'
import { arrayIncludesObject } from '../../helpers/array';
import { SvgIco } from "../../SharedComponents/Icons";

class SelectLocationTypeEntryPage extends Component {

  itemIsSelected = (id) => {
    return arrayIncludesObject(this.props.selectedData, 'id', id);
  };


  render() {
    const {
      dynamicData,
      selectAction,
      placeholder,
      subSite,
    } = this.props;

    return (
      <div id="selectTypes">
        {subSite === 'sales' ? (
          <div className='select-type-sales'>
            <div className="col-md-2 no-padding select-type-sales__title">
              <p>{placeholder}</p>
            </div>
            <div className="col-md-10 no-padding mobile-columns">
              {dynamicData.map(item => {
                return (
                  <div key={item.id} className={`select-type__checkbox-wrapper ${this.itemIsSelected(item.id) ? "select-type__checkbox-wrapper--selected" : '' }`} onClick={e => selectAction(e, item)}>
                    <p>{item.name}</p>
                    <SvgIco name="check" size={16} />
                  </div>
                )})}
            </div>
          </div>
          ) : (
            <div className='select-type'>
              {dynamicData.map(item => {
                return (
                  <div key={item.id} className={`select-type__checkbox-wrapper ${this.itemIsSelected(item.id) ? "select-type__checkbox-wrapper--selected" : '' }`} onClick={e => selectAction(e, item)}>
                    <p>{item.name}</p>
                    <SvgIco name="check" size={16} />
                  </div>
                )})}
            </div>
          ) }
      </div>
    );
  }
}

export default SelectLocationTypeEntryPage;
