import React, { Fragment } from "react";
import objectPath from 'object-path';
import classNames from 'classnames';
import { isMobile, isTablet, isIPad13, isMobileOnly } from 'react-device-detect';

import { apiProvider } from '../../../api/core';
import { urlKindsQueryBuilder } from '../../../utils/urlQueryBuilder';
import { SvgIco } from "../../SharedComponents/Icons";

import './LocationNavigator.sass';


class LocationNavigator extends React.PureComponent {

  state = {
    locationsData: {},
    locationsList: {},
    kindFilters: [],
    navigationPath: [],
    navigationPathList: this.props.siteKey === 'global' ? ['countries', 'provinces', 'postal_districts'] : ['postal_districts'],
    countryAlpha2: '',
    breadcrumbPath: [],
    isTablet: window.innerWidth <= 768,
    isMobile: window.innerWidth <= 480,
  };

  componentDidMount() {
    const { siteKey, chooseProvinceJsArgs, allCountries } = this.props;
    const { globalStatsUrl, statsUrl } = chooseProvinceJsArgs;
    const basePathsForKinds = {};
    const isGlobal = siteKey === 'global';
    const url = isGlobal ? globalStatsUrl : statsUrl;
    const currentCountry = !isGlobal && allCountries.filter(country => country.data.alpha2.toLowerCase() === siteKey)[0].data.name;
    const breadcrumbInitialPath = isGlobal ? { name: 'Continents', path: ''} : { name: currentCountry, path: '' };
    const preselectedCountry = chooseProvinceJsArgs['preselectedCountry'];

    Object.keys(chooseProvinceJsArgs['basePathsForKinds']).forEach(kind => basePathsForKinds[kind.toLowerCase()] = chooseProvinceJsArgs['basePathsForKinds'][kind]);

    this.setState({
      breadcrumbPath: [ breadcrumbInitialPath ],
      countryAlpha2: siteKey === 'global' ? preselectedCountry || '' : siteKey,
      basePathsForKinds: basePathsForKinds,
    }, () => {
      apiProvider.getAll(url)
        .then(response => {
          if (response) {
            chooseProvinceJsArgs['preselectedCountry'] && this.renderPreselectedCountryPath(preselectedCountry, response);
            this.setState({ locationsData: response }, () => this.updateLocationsList(true));
          }
        });
    });
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    this.props.selectedKinds !== prevProps.selectedKinds && this.setState({ kindFilters: this.props.selectedKinds }, () => { this.updateLocationsList() });
  }

  renderPreselectedCountryPath = (countryCode, data) =>
    Object.keys(data).forEach(partOfTheWorld => {
      const path = [partOfTheWorld, 'countries', countryCode, 'provinces'];

      if(objectPath.get(data, path)) {
        const breadcrumbPath = [
          { name: objectPath.get(data, path.slice(0, path.length - 1)).name, path: countryCode }
        ];

        this.setState({
          navigationPath: path,
          countryAlpha2: countryCode,
          breadcrumbPath: [ ...breadcrumbPath ] });
      }
    });

  updateLocationsList = (isInitialLoad) => {
    const { navigationPath, locationsData } = this.state;
    const locationsDataByPath = objectPath.get(locationsData, navigationPath);
    const navigationCount = navigationPath.length ? navigationPath.length / 2 : 0;
    const locationsListResult = {};

    Object.keys(locationsDataByPath).forEach(location => {

      const locationItem = locationsDataByPath[location];

      if(Object.keys(locationItem).length !== 0 ) {

        const totalLocationDistrictsCount = navigationPath[navigationPath.length - 1] === 'postal_districts'
          ? this.totalDistrictsCount(locationItem, navigationCount, true)
          : this.totalDistrictsCount(locationItem, navigationCount);

        if(totalLocationDistrictsCount) {
          locationsListResult[locationItem.name] = {
            name: locationItem.name,
            totalCount: totalLocationDistrictsCount,
            slug: locationItem.slug || null,
            path: location,
            country_alpha2: locationItem.country_alpha2,
            isPostalDistrict: navigationPath[navigationPath.length - 1] === 'postal_districts',
          };
        }
      }
    });

    this.setState({ locationsList: locationsListResult }, () => {
      if (isInitialLoad && Object.keys(this.state.locationsList).length === 1) {
        this.updateNavigationPath(Object.values(this.state.locationsList)[0].path, Object.values(this.state.locationsList)[0].name)
      }
    });
  };

  totalDistrictsCount = (location, count, isPostalDistrict) => {
    const currentPosition = this.state.navigationPathList[count];
    const currentNode = location[currentPosition];

    return isPostalDistrict
      ? this.filterDistrictCount(location)
      : !currentNode || Object.keys(currentNode).length === 0
      ? 0
      : Object.keys(currentNode).reduce((sum, location) =>
          sum + (currentPosition === 'postal_districts' ? this.filterDistrictCount(currentNode[location]) : this.totalDistrictsCount(currentNode[location], count + 1)), 0);
  };

  filterDistrictCount = (district) => {
    const { kindFilters } = this.state;

    return kindFilters.length
      ? Object.keys(district.sums).reduce((sum, kind) => {
          const secondaryKindCount = !kindFilters.includes(kind)
            ? district.sums[kind]['secondary']
              .reduce((sum, secondaryKind) => sum + secondaryKind
                .reduce((sum, kind) => +kindFilters.includes(kind) + sum, 0)
                , 0)
            : 0;
          const directKindsCount = kindFilters.includes(kind) ? district.sums[kind]['direct'] : 0;

          return sum + directKindsCount + secondaryKindCount;
        }, 0)
      : district.total_sum;
  };

  setLocationLink = (slug) => {
    const { kindFilters, basePathsForKinds, countryAlpha2 } = this.state;
    const { chooseProvinceJsArgs, siteKey, currentLocalePathSegment } = this.props;
    const selectedCountry = siteKey === 'global' ? `/${ countryAlpha2 }` : '';
    const singleKindUrl = kindFilters.length && kindFilters.length === 1 && basePathsForKinds[kindFilters[0].replace('_', '').toLowerCase()];

    return currentLocalePathSegment + selectedCountry + urlKindsQueryBuilder(chooseProvinceJsArgs['provinceBasePath'], slug, kindFilters, singleKindUrl);
  };

  updateNavigationPath = (path, name) => {
    const { navigationPath, navigationPathList, breadcrumbPath, isMobile, isTablet } = this.state;
    const currentLocationsType = navigationPath[navigationPath.length - 1];
    const updatedState = {
      navigationPath: [ ...navigationPath, path, navigationPathList[navigationPath.length / 2]],
      breadcrumbPath: [ ...breadcrumbPath, { name: name, path: path } ],
    };

    if(this.props.siteKey === 'global' && currentLocationsType === 'countries'){
      updatedState.countryAlpha2 = path;
    }

    currentLocationsType !== 'postal_districts' && this.setState({
      ...this.state,
      ...updatedState,
    }, () => this.updateLocationsList());

    (isMobile || isTablet) && this.handleScrollToTop();
  };

  handleBreadcrumbClick = (name, index, path) => {
    const { navigationPath, breadcrumbPath } = this.state;
    const targetLocation = path;
    const updatedBreadcrumbsPath = breadcrumbPath.slice(0, targetLocation ? +index + 1 : 1);
    const updatedNavigationPath = navigationPath.slice(0, targetLocation ? navigationPath.indexOf(targetLocation) + 2 : 0);

    this.setState({
      navigationPath: updatedNavigationPath,
      breadcrumbPath: updatedBreadcrumbsPath,
    }, () => this.updateLocationsList());
  };

  handleGoBack = () => {
    const currentPath = this.state.navigationPath.slice(0, this.state.navigationPath.length - 2);
    const breadcrumbPath = this.state.breadcrumbPath;

    breadcrumbPath.length !== 1 && breadcrumbPath.pop();

    this.setState({
      navigationPath: currentPath,
      breadcrumbPath: breadcrumbPath,
    }, () => this.updateLocationsList());
  };

  renderLocations = (locationsList) =>
    Object.keys(locationsList)
      .sort((a, b) => this.props.siteKey === 'global' || this.state.navigationPath.length > 0 ? a.localeCompare(b, undefined, {numeric: true, sensitivity: 'base'}) : locationsList[b].totalCount - locationsList[a].totalCount)
      .map(location => {
        const currentLocation = locationsList[location];

        return currentLocation.isPostalDistrict
          ? <a className="ep-location-navigator__location-link"
               key={currentLocation.name}
               href={this.setLocationLink(currentLocation.slug, currentLocation.country_alpha2)}>
              <p>{`${currentLocation.name} (${currentLocation.totalCount})`}</p>
            </a>
          : <div className="ep-location-navigator__location-link"
               data-path={currentLocation.path}
               data-name={currentLocation.name}
               key={currentLocation.name}
               onClick={() => this.updateNavigationPath(currentLocation.path, currentLocation.name)}>
              <p>{`${currentLocation.name} (${currentLocation.totalCount})`}</p>
            </div>;
      });

  handleScrollToTop = () => {
    this.state.isMobile ? this.props.navigationFilterRef.scrollIntoView() : this.props.navigationContainerRef.scrollIntoView();
  };

  render() {
    const { navigationPath, locationsList, breadcrumbPath, locationsData } = this.state;
    const { mapImg, chooseProvinceJsArgs } = this.props;
    const isFirstPage = navigationPath.length === 0;
    const endOfNavigation = navigationPath[navigationPath.length - 1] === 'postal_districts';
    const preselectedCountry = chooseProvinceJsArgs['preselectedCountry'];
    const isFirstPageOfPreselectedCountry = preselectedCountry && breadcrumbPath.length === 1;
    const headerClasses = classNames('ep-location-navigator__header', {'not-active': isFirstPage });
    const wrapperClasses = classNames('ep-location-navigator__wrapper',
      { 'cols-2': isFirstPage },
      { 'cols-3': !isFirstPage },
      { 'shorten': endOfNavigation },
      { 'tablet': isTablet || isIPad13 });


    return (
      <div className="ep-location-navigator">
        <div className={wrapperClasses}>
          { (!isMobileOnly && (!(isMobile || isIPad13) || ((isMobile || isIPad13) && isFirstPage))) &&
            <div className="ep-location-navigator__map-col">
              <img className="lazyload" data-src={require(`../../../src/images/${mapImg}`)} alt="map" height={163} data-sizes="auto"/>
            </div>
          }
          <div className="ep-location-navigator__main-block-wrapper">
            <div className={"ep-location-navigator__main-block"}>
              <div className={headerClasses}>
                { !isFirstPage && !isFirstPageOfPreselectedCountry &&
                  <Fragment>
                    <div className="ep-location-navigator__back-btn" onClick={ this.handleGoBack }>
                      <SvgIco name="arrow-back" size={18} />
                      <span>{ I18n.t('generic.back') }</span>
                    </div>
                    <div className="ep-location-navigator__breadcrumbs">
                      { breadcrumbPath.map((link, index) =>
                        <div className="ep-location-navigator__breadcrumbs-link" key={link.name}>
                          <span data-name={link.name}
                                data-index={index}
                                data-path={link.path}
                                onClick={ () => this.handleBreadcrumbClick(link.name, index, link.path)}
                          >
                            { link.name }
                          </span>
                          <SvgIco name="keyboard-arrow-right-rounded" size={16} />
                        </div>) }
                    </div>
                  </Fragment>
                }
              </div>
              { !Object.keys(locationsList).length && Object.keys(locationsData).length
                ?
                  <div className="ep-location-navigator__no-premises">
                    { I18n.t('entry_pages.choose_province.no_premises_in_region') }
                  </div>
                :
                  <div className="ep-location-navigator__locations-list">
                    { this.renderLocations(locationsList) }
                  </div>
              }
            </div>
            { endOfNavigation &&
              <div className="ep-location-navigator__footer">
                <a href={this.setLocationLink(navigationPath[navigationPath.length - 2])} className="ep-location-navigator__show-all-link">
                  { I18n.t('entry_pages.choose_province.show_all_locations_in_province') }
                </a>
              </div>
            }
          </div>
        </div>
      </div>
    )
  }
}


export default LocationNavigator;
