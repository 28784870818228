import React, { Component } from 'react';
import update from 'immutability-helper';
import classNames from 'classnames';
import { isMobileOnly } from 'react-device-detect';

import { fetchLocations } from '../../../utils/api';
import { getObjectIndexInArray } from '../../helpers/array';
import titleImage from '../../../src/images/entry_pages/title-banner.png';
import arrowBottom from '../../../src/images/icons/arrow-bottom-mobile.svg';
import SelectLocationTypeEntryPage from '../SelectLocationTypeEntryPage/SelectLocationTypeEntryPage';
import LocationSearchEntryPage from '../LocationSearchEntryPage/LocationSearchEntryPage';

import './MainSearchBlock.sass';


class MainSearchBlock extends Component {
  constructor(props) {
    super(props);

  }

  state= {
    query: '',
    firstImage: true,
    results: [],
    selectedPropertyTypes: [],
    selectedAvailableKinds: [],
    showNoResultsError: false,
    tabletOrMobile: window.innerWidth <= 960,
    selectedKindsUpdated: []
  };

  search_id = new Date().getTime() % 2147483647;

  mapSelectedKinds = (selectedKinds) => {
    return selectedKinds.map( elem => {
      return { name: elem.label, id: elem.key };
    });
  };

  mapPreselectKinds = (locationTypesMapped) => {
    return locationTypesMapped.filter( elem => {
      if (this.state.selectedKindsUpdated.includes(elem.id)){
        return elem
      }
    })
  };
  handleKindsSelect = (kind) => {
    const { selectedKindsUpdated } = this.state;
    const updatedKinds = selectedKindsUpdated.indexOf(kind) >= 0 ? selectedKindsUpdated.filter(selectedKindsUpdated => selectedKindsUpdated !== kind) : [...selectedKindsUpdated, kind];
    const locationTypesMapped = this.mapSelectedKinds(this.props.updatedKinds);

    this.setState({ selectedKindsUpdated: updatedKinds }, () => {
      const test = this.mapPreselectKinds(locationTypesMapped);
      this.setState({ selectedPropertyTypes: test });
    });

  };

  componentDidMount() {
    const preselectedKinds = this.props.searchAreaConfiguration.searchOptions.preselectedKinds;
    preselectedKinds.length > 0 && preselectedKinds.map(elem => this.handleKindsSelect(elem));
    // except this only for 'sales section' investment checkbox
    if (this.props.subSite === 'sales' && this.props.searchAreaConfiguration.searchOptions.preselectedSections === "investment") {
      this.setState({ selectedPropertyTypes: [{id: 'investment'}] });
    }

  }

  handleTypesChange = (e, item) => {
    const index = getObjectIndexInArray(this.state.selectedPropertyTypes, 'id', item.id)
    const itemIsSelected = index !== -1;

    this.setState({
      selectedPropertyTypes: (itemIsSelected
        ? update(this.state.selectedPropertyTypes, {$splice: [[index, 1]] })
        : update(this.state.selectedPropertyTypes, {$push: [item]}))
    }, () => {
      if (/\S/.test(this.state.query)) {
        this.fetchItems(this.state.query)
      }
    })
  };

  handleAvailableKindsChanged = (e, item) => {
    const index = getObjectIndexInArray(this.state.selectedAvailableKinds, 'id', item.id)
    const itemIsSelected = index !== -1;

    this.setState({
      selectedAvailableKinds: (itemIsSelected
        ? update(this.state.selectedAvailableKinds, {$splice: [[index, 1]] })
        : update(this.state.selectedAvailableKinds, {$push: [item]}))
    }, () => {
      if (/\S/.test(this.state.query)) {
        this.fetchItems(this.state.query)
      }
    })
  };

  mapTypes = typesObj => typesObj.map(typeChk => typeChk.id);

  fetchItems = query => {
    const { searchAreaConfiguration, subSite } = this.props;
    const { selectedPropertyTypes, selectedAvailableKinds } = this.state;
    const checkSalesSection = subSite === 'sales';
    const kinds = this.mapTypes(checkSalesSection ? selectedAvailableKinds : selectedPropertyTypes);
    let sections = this.mapTypes(selectedPropertyTypes);

    if (sections.length === 0) {
      sections = ['foreclosure', 'investment', 'own_use']
    }

    const params = {
      query,
      kinds,
      search_id: this.search_id,
      sections: checkSalesSection ? sections : [window.env.section],
      max_entries: this.state.tabletOrMobile ? 8 : 12
    };

    fetchLocations(searchAreaConfiguration.apiAutocompletePath, params).then(data => {
      const results = data.data;
        this.setState({ results, showNoResultsError: !results.length });
    });
  };

  controlSearchQuery = (query) => this.setState({query});

  clearResults = (results) => this.setState({results});

  mapAvailableSections =(availableSections) => {
    return availableSections.map(elem => {
      return {name: elem.label, id: elem.key }
    })
  };

  render() {
    const {
      mainHeadline,
      searchBlockHeader,
      siteKey,
      subSite,
      searchAreaConfiguration,
      updatedKinds,
      contactInfoPhone,
      contactInfoPhoneWithoutCode,
      searchQueryStringFromGoogle,
    } = this.props;

    let availableSections, availableKinds;
    const locationTypesMapped = this.mapSelectedKinds(updatedKinds);
    const {  results, showNoResultsError } = this.state;
    const phoneNumber = siteKey === 'global' ? contactInfoPhone : contactInfoPhoneWithoutCode;
    const titleWrapperClasses = classNames('title-wrapper', {'desktop-indent': !contactInfoPhone });

    if (subSite === 'sales') {
      availableSections = this.mapAvailableSections(searchAreaConfiguration.searchOptions.availableSections)
      availableKinds = this.mapAvailableSections(updatedKinds)
    }

    return (
      <div>
        <div className="main-searchcontainer">
          <div className="background-grey" />
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                { contactInfoPhone &&
                  <div className="phone-block">
                    { I18n.t(`generic.call_us`) }: <a href={`tel://${contactInfoPhone}`}>{ phoneNumber }</a>
                  </div>
                }
                <div className={titleWrapperClasses}>
                  <div className="title-text">
                    <h1 className="entry-page-main-title">{mainHeadline}</h1>
                    <h2 className="search-title" dangerouslySetInnerHTML={{__html: searchBlockHeader }} />
                  </div>

                  { !isMobileOnly &&
                    <div className="title-image">
                      <img data-src={titleImage} data-sizes="auto" className="lazyload" alt="title illustration" width={249} height={140} />
                    </div>
                  }
                </div>
              </div>
            </div>
            <img className="arrow-bottom-mobile lazyload" data-src={ arrowBottom } data-sizes="auto" alt="arrow bottom mobile "/>
          </div>



          <div className="container wrapper-container">
            <div className="search-wrapper">

              <div className="inner-container">
                <h3>{I18n.t('entry_pages.main_search.find_premises')}</h3>

                { subSite === 'sales' ? (
                  <div className="sales-wrapper">
                    <SelectLocationTypeEntryPage
                      dynamicData={availableSections}
                      selectedData={this.state.selectedPropertyTypes}
                      selectAction={this.handleTypesChange}
                      subSite={subSite}
                      placeholder={I18n.t('apps.lb_showroom.main.sections_placeholder')}
                    />
                    <SelectLocationTypeEntryPage
                      dynamicData={availableKinds}
                      selectedData={this.state.selectedAvailableKinds}
                      selectAction={this.handleAvailableKindsChanged}
                      placeholder={I18n.t('apps.lb_showroom.main.kinds_placeholder')}
                      subSite={subSite}
                    />
                  </div>
                ) : (
                  <SelectLocationTypeEntryPage
                    dynamicData={locationTypesMapped}
                    selectedData={this.state.selectedPropertyTypes}
                    selectAction={this.handleTypesChange}
                    />
                )}

                <LocationSearchEntryPage
                  results={results}
                  siteKey={siteKey}
                  showNoResultsError={showNoResultsError}
                  fetchItems={ this.fetchItems }
                  controlSearchQuery={this.controlSearchQuery}
                  clearResults={this.clearResults}
                  searchQueryStringFromGoogle={searchQueryStringFromGoogle}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default React.memo(MainSearchBlock);
